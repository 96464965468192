import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
// import SEO from "../components/seo"
import Img from "gatsby-image"
import { Container, Row, Col, Button, Carousel, Navbar, Jumbotron, CardDeck, Card, Accordion } from 'react-bootstrap';

const IndexPage = ({data}) => (
  <Layout>
    <Container fluid>
      <Row className="banner">
        <Col>
          <div><b>SHOP HOLIDAY SPECIAL’S 25% OFF</b></div> 
          <div>+ FREE SHIPPING</div>
        </Col>
      </Row>
    </Container>

    <Navbar bg="light" expand="lg" className="mainNav">
      <Navbar.Brand className="mainLogo">
        <Img fluid={data.mainLogo.childImageSharp.fluid} />
      </Navbar.Brand>
    </Navbar>

    <Container className="mx-auto my-3">
      <Row className="mainSection">
        <Col xs={12} md={6}>
          <Carousel>
            <Carousel.Item>
              <Image />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage2.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage3.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage4.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage5.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage6.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage7.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage8.childImageSharp.fluid} />
            </Carousel.Item>
            <Carousel.Item>
              <Img fluid={data.carouselImage9.childImageSharp.fluid} />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col>
              <h1>Lavender Aromatherapy Weighted Blanket <span className="fontNormal"> (15lbs, Queen Size)</span></h1>
            </Col>
          </Row>
          <Row className="smallDescription">
            <Col>
              <span>Dried Organic Lavender · No Chemicals / Additives</span>
            </Col>
          </Row>
          <Row className="pricingText">
            <Col>
              <span>$124 <s>$165</s> USD · 4.9 ⭐️ / 5 (68 Reviews)</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <a href="https://essentialblankets.com/10029891635/checkouts/b49d2f25f2ac2e8b8b2209e764e93f8f">
                <Button variant="primary" className="mainButton" size="lg" block>Order Now</Button>
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>
                The #1 Way To Fall Asleep, Naturally
              </h1>
              <p>
                Put your anxiety to bed. We combined organic lavender with deep-touch 
                pressure to end restless nights & help you fall asleep in half the time.
              </p>
            </Col>
          </Row>
          <Row> 
            <Accordion className="productDetails">
              <Card className="productSectionDetails">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <h2>· Details ·</h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>The exact dimensions are 78 x 60 inches.
                  The best smelling blanket on the planet. NO chemicals. NO 
              additives. JUST dried flowers ⚘
              Our 15lb Queen sized weighted blanket is infused with 150 
              grams of dried organic lavender flowers for a subtle and calming aroma.
                  We combined the deep pressure touch and the natural calming 
              properties of lavender to create a powerful relaxation device.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  <h2>· Returns & 30 Day Guarantee ·</h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>The scent will last up to 5 years depending on how frequently 
                    you wash the blanket.</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  <h2>· Care ·</h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    Gently hand wash with cold water. Avoid bleaching, soaking, 
                    fabric softener or hot water. Please do not tumble dry or dry clean. 
                    For drying, please dry lay out the blanket on a flat surface.

                    Care instructions included, scent can last for years!
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Row>
        </Col>
      </Row>
    </Container>

    <Container className="cardSection">
      <Row>
        <Col>
          <h1>Recommended by 100’s of Cognitive Therapists & Aromatherapy Experts</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="youtube" data-embed="tkKvSxpjcqk"> 
            <div className="play-button"></div> 
            <Img fluid={data.videoExpert.childImageSharp.fluid} />
          </div>
        </Col>
      </Row>
    </Container>

    <Container>
      <Carousel>
        <Carousel.Item>
          <Row className="reviewBox">
            <Col>
              <div className="unicodeStars">★ ★ ★ ★ ★</div>
              <div className="reviewQuote">
                “Absolutely love this blanket! Have used for the past few nights, fall asleep so fast, the smell is dreamy!”
              </div>
              <div className="reviewAuthor">Maria M.</div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="reviewBox">
            <Col>
              <div className="unicodeStars">★ ★ ★ ★ ★</div>
              <div className="reviewQuote">
                “The lavender scent is so calming. Puts me right to sleep. I've gifted the blanket to three other friends - each of them loved it!”
              </div>
              <div className="reviewAuthor">Julia G.</div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="reviewBox">
            <Col>
              <div className="unicodeStars">★ ★ ★ ★ ★</div>
              <div className="reviewQuote">
                “The blanket is the best thing I've ever bought for my anxiety. It tops my diffuser, bath salts, scented candles - everything!”
              </div>
              <div className="reviewAuthor">Alyssa A.</div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="reviewBox">
            <Col>
              <div className="unicodeStars">★ ★ ★ ★ ★</div>
              <div className="reviewQuote">
                “So in love with my blanket. It is scented just right. The weight is perfect. I snuggle down underneath to sleep and there I go to dreamland.”
              </div>
              <div className="reviewAuthor">Lisa N.</div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="reviewBox">
            <Col>
              <div className="unicodeStars">★ ★ ★ ★ ★</div>
              <div className="reviewQuote">
                “I used to wake up all the time and not be able to fall back asleep. Now I sleep peacefully through the night and fall asleep almost immediately <span role="img" aria-label="smile">😊</span>”
              </div>
              <div className="reviewAuthor">Michelle M.</div>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="reviewBox">
            <Col>
              <div className="unicodeStars">★ ★ ★ ★ ★</div>
              <div className="reviewQuote">
                “This blanket smells so wonderful and it makes me immediately relax. The first day I used this blanket, I took an hour nap and it was bliss.”
              </div>
              <div className="reviewAuthor">Jordan L.</div>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>

    </Container>

    <Container className="mx-auto my-5">
      <Row>
        <Col>
          <h1>Watch Customers Try Their Essential Blanket For The First Time</h1>
        </Col>
      </Row>
      <Row>
        <Col className="videoLoad">
        <div className="youtube" data-embed="0NE4AhwQlXE"> 
          <div className="play-button"></div> 
          <Img fluid={data.videoTestimonial.childImageSharp.fluid} />
        </div>
        </Col>
      </Row>
    </Container>

    <Container id="why-lavender" className="mx-auto my-5 cardSection">
      <Row>
        <Col>
          <h1>Why use a lavender-infused + weighted blanket?</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <CardDeck>
            <Card>
              <Card.Title><h2 className="featureHighlight">1. Fall asleep faster, for longer</h2></Card.Title>
              <Card.Text className="featureDescription">
                Studies have shown that lavender can decrease the time is takes to 
                fall asleep and increase deep sleep throughout the night. Wake-up without the
                grogginess of sleep medication.
              </Card.Text>
              <Img fluid={data.featureImage1.childImageSharp.fluid} />
            </Card>
            <Card>
              <Card.Title><h2 className="featureHighlight">2. Put anxiety to bed</h2></Card.Title>
              <Card.Text className="featureDescription">
                Multiple studies have shown that lavender has similar anti-anxiety
                benefits to those seen with prescription anxiety medication. Feel at ease in no time, without
                the drugs.
              </Card.Text>
              <Img fluid={data.featureImage2.childImageSharp.fluid} />
            </Card>
            <Card>
              <Card.Title><h2 className="featureHighlight">3. Stop tossing, turning, & fidgeting</h2></Card.Title>
              <Card.Text className="featureDescription">
                Weighted blankets use "deep touch pressure" to emulate the feeling of 
                being held — sort of like a hug! This reduces bed time twitching and restlessness,
                making it easy to fall asleep & stay asleep.
              </Card.Text>
              <Img fluid={data.featureImage3.childImageSharp.fluid} />
            </Card>
          </CardDeck>
        </Col>
      </Row>
    </Container>

    <Jumbotron className="highlightCallOut">
      <Col>
        <h1 className="highlightTitle">Pay in Four Separate Installments</h1>
        <p className="highlightDescription">
        Just choose “Pay with Sezzle” during the checkout.
        </p>
      </Col>
      <Col>
        <a href="https://essentialblankets.com/10029891635/checkouts/b49d2f25f2ac2e8b8b2209e764e93f8f">
          <Button variant="primary" className="mainButton" size="lg" block>Add to Cart</Button>
        </a>
      </Col>
    </Jumbotron>

    <Container className="mx-auto my-5">
      <Row>
        <Col className="faqTitle centerText">
          <h1>FAQ</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>1. How big is the blanket?</h2>
          <p className="faqText">The exact dimensions are 78 x 60 inches.</p>
          <h2>2. How long will the scent last?</h2>
          <p className="faqText">The scent will last up to 4 years, depending on how frequently you wash the blanket.</p>
          <h2>3. How do I wash the blanket?</h2>
          <p className="faqText">You can machine wash in cold on “delicate,” or hand wash. Avoid heat & bleaching. 
            Please do not tumble dry or dry clean. For drying, please dry lay out the blanket 
            on a flat surface.</p>
          <h2>4. Is the product organic?</h2>
          <p className="faqText">Yes! We use 100% organic cotton, and the blankets scent is from organic lavender buds.
          No additives, or chemicals. Just lavender!</p>
        </Col>
      </Row>
    </Container>

    <Jumbotron className="highlightCallOut">
      <Col>
        <h1 className="highlightTitle">Stop Tossing & Turning, Today!</h1>
        <p className="highlightDescription">
        Hurry up & grab the best sleep of your life, before we sell out for the season!
        </p>
      </Col>
      <Col>
        <a href="https://essentialblankets.com/10029891635/checkouts/b49d2f25f2ac2e8b8b2209e764e93f8f">
          <Button variant="primary" className="mainButton" size="lg" block>Add to Cart</Button>
        </a>
      </Col>
    </Jumbotron>

    <Jumbotron className="footerSection">
      <Container className="footer">
        <Row className="footer-block">
          <Col className="footerImage">
            <Img fluid={data.footerLogo.childImageSharp.fluid} />
          </Col>
        </Row>
        <Row className="footer-block">
          <Col>
            <p className="footer-description">
              Essential Store creates therapeutic goods infused with dried flowers. 
              NO chemicals. NO additives. JUST dried flowers ⚘
            </p>
          </Col>
        </Row>
        <Row className="footer-block">
          <Col>
            <span className="footer-words">
              Designed with <span className="heart">❤</span> in USA & Canada
            </span>
            <span className="footer-words">
              ©2020 Copyright. All rights reserved
            </span>
          </Col>
        </Row>
      </Container>
    </Jumbotron>

  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    ccLogo: file(relativePath: { eq: "cc_logos.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }    
    mainLogo: file(relativePath: { eq: "icon-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage1: file(relativePath: { eq: "women-lavender-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage2: file(relativePath: { eq: "white-blanket-lavender-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage3: file(relativePath: { eq: "lavender-blanket-7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage4: file(relativePath: { eq: "white-blanket-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage5: file(relativePath: { eq: "lavender-blanket-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage6: file(relativePath: { eq: "women-lavender-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage7: file(relativePath: { eq: "lavender-blanket-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage8: file(relativePath: { eq: "women-lavender-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carouselImage9: file(relativePath: { eq: "info-explain-material.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureImage1: file(relativePath: { eq: "care-girl-blanket.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureImage2: file(relativePath: { eq: "diffuser-bed-product-fs8.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureImage3: file(relativePath: { eq: "women-lavender-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    subscriptionImage: file(relativePath: { eq: "lavender-blanket-1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videoTestimonial: file(relativePath: { eq: "video-testimonial-thumbnail.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videoExpert: file(relativePath: { eq: "video-expert-thumbnail.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    footerLogo: file(relativePath: { eq: "essential-blankets-footer.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }

`
